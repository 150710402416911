
      const favicon = document.createElement('link');
      favicon.setAttribute('type', 'image/png');
      favicon.setAttribute('rel', 'favicon icon');
      document.head.appendChild(favicon);

      const darkModeMediaQuery = window.matchMedia(
        '(prefers-color-scheme: dark)'
      );
      if (darkModeMediaQuery.matches) {
        favicon.setAttribute('href', '/static/images/favicon_green.ico');
      } else {
        favicon.setAttribute('href', '/static/images/favicon.ico');
      }

      darkModeMediaQuery.addListener((e) => {
        const darkModeOn = e.matches;
        if (darkModeOn) {
          favicon.setAttribute('href', '/static/images/favicon_green.ico');
        } else {
          favicon.setAttribute('href', '/static/images/favicon.ico');
        }
      });
    